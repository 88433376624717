@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
}
body {
  @apply font-montserrat;
}
a li {
  list-style-type: none;
}
a {
  cursor: pointer;
}

.btn {
  @apply px-5 py-2 rounded-xl border-4 font-medium inline-block text-tinybase md:text-base;
}

.btn-primary {
  @apply bg-primary text-white;
}

.btn-primary:hover {
  @apply hover:bg-lightPrimary;
}

.btn-secondary {
  @apply inline-block border border-black text-black bg-white font-bold text-base;
}

.btn-secondary:hover {
  @apply hover:bg-primary hover:text-white;
}
